import PhaseManager, { Phase } from './PhaseManager';
import {Msg, updateStatus, webSocket} from '../app';
import Player from '../models/Player';

export const clientPlayer = new Player();

class RegisterPlayer implements Phase
{
    private form: HTMLElement;
    private player: Player;
    private views: HTMLCollectionOf<HTMLElement>;
    private activeView: number = 0;
    private playerIsRegistered: boolean = false;

    constructor(player: Player) {
        this.player = player;
        this.form = document.getElementById('registration-form');

        this.form.addEventListener('submit', (evt) => {

            // TODO uncomment for development
            // evt.preventDefault();
            // this.onPlayerRegistered(JSON.stringify({name: 'test'}));

            // TODO uncomment for production
            this.onSubmit(evt);
        });

        let container = document.getElementById('welcome');
        this.views = container.getElementsByClassName('view');

        const startButton = document.getElementById('startGame');

        startButton.addEventListener('submit', e => {
            e.preventDefault();

            updateStatus('ready');
            this.nextView();
        });
    }

    private onSubmit(evt: Event){
        evt.preventDefault();

        let userInfo = {
            // @ts-ignore
            'full_name': this.form.elements[0].value,
            // @ts-ignore
            'email': this.form.elements[1].value,
            // @ts-ignore
            'organisation': this.form.elements[2].value,
            // @ts-ignore
            'region': this.form.elements[3].value,
            // @ts-ignore
            'signup': this.form.elements[4].value === 'on'? 1 : 0
        };

        let msg: Msg = {
            type: 'command',
            subType: 'register',
            body: JSON.stringify(userInfo)
        };

        webSocket.send(JSON.stringify(msg));

    }

    public onPlayerRegistered(data: string){
        const { name } = JSON.parse(data);
        this.player.setName(name);
        this.playerIsRegistered = true;

        console.log('player registered as ', name);

        this.end();
    }

    private nextView(){

        this.views[this.activeView].style.display = 'none';
        this.activeView += 1;
        this.views[this.activeView].style.display = 'flex';
    }

    public hasEnded() {
        return this.playerIsRegistered;
    }

    public start(){
        this.views[this.activeView].style.display = 'flex';
    }

    public next(){
        this.nextView();
    }

    public end() {
        this.views[this.activeView].style.display = 'none';

        PhaseManager.next();
    }

}

export default new RegisterPlayer(clientPlayer);