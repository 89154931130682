import { updateStatus } from '../app';
import GameSequence from '../models/GameSequence';
import ResultsManager from "./ResultsManager";
import RegisterPlayer from "./RegisterPlayer";

export interface Phase {
    next(): void;
    start(): void;
    hasEnded(): boolean;
}

const phaseObjects = {
    register: RegisterPlayer,
    game: GameSequence,
    results: ResultsManager
};

let phaseEl = document.getElementById('phase');

class PhaseManager {

    private phaseIdx: number = 0;
    private phase: Phase = Object.values(phaseObjects)[this.phaseIdx];

    constructor(){
        this.phase.start();
    }

    public startNextPhase(){
        this.phaseIdx += 1;
        this.phase = Object.values(phaseObjects)[this.phaseIdx];
        console.log('started next phase: ', Object.keys(phaseObjects)[this.phaseIdx]);

        this.phase.start();
    }

    public next() {

        updateStatus('busy');

        //phase start end or next.
        if (this.phase.hasEnded()) {
            this.startNextPhase();
        } else {
            this.phase.next();
        }

        phaseEl.innerHTML = '' + this.phaseIdx;
    }

    public processStep(detail: string){
        switch (detail) {
            case 'next':
                this.next();
                break;
        }
    }

}

const initGameSessionManager = new PhaseManager();

export default initGameSessionManager;