import { Phase } from "./PhaseManager";
import { webSocket, Msg } from '../app';
import { clientPlayer } from './RegisterPlayer';

interface Result {
    remainingTime: number;
    isCorrectAnswer: boolean;
}

class ResultsManager implements Phase
{
    private clientResults: Result[] = [];
    private clientTotal: number = 0;
    private opponentTotal: number = 0;
    private receivedOpponentScore: boolean = false;
    private views: HTMLCollectionOf<HTMLElement>;
    private activeView: number = 0;

    constructor(){
        let container = document.getElementById('results');
        this.views = container.getElementsByClassName('view');
    }

    public getResults = () => this.clientResults;

    public getWinner(){
        const texts = {
            preText: '',
            text: ''
        };

        switch (true) {
            case this.clientTotal > this.opponentTotal:
                texts.preText = 'CONGRATULATIONS';
                texts.text ='You Win';
                return texts;
            case this.opponentTotal > this.clientTotal:
                texts.preText = 'SORRY';
                texts.text ='You Lose';
                return texts;
            case this.clientTotal === this.opponentTotal:
                texts.preText = 'YOU';
                texts.text ='Tied';
                return texts;
            default:
                texts.preText = 'We are all';
                texts.text ='winners';
                return texts;
        }
    }

    private nextView(){
        this.views[this.activeView].style.display = 'none';
        this.activeView += 1;
        this.views[this.activeView].style.display = 'flex';
    }

    public fetchResults(){

        let msg: Msg = {
            type: 'request',
            subType: 'results',
            body: ''
        };

        webSocket.send(JSON.stringify(msg));
    }

    public onReceiveAllResults(body: string) {
        let highScoreResults = JSON.parse(body);
        this.renderHighscoreList(highScoreResults);
    }

    public onReceiveResult(opponentResult: number) {
        this.opponentTotal = opponentResult;
        this.receivedOpponentScore = true;

        this.renderReveal();
    }

    private postTotal(){
        let total = this.getTotal();

        let msg: Msg = {
            type: 'command',
            subType: 'save-result',
            body: String(total)
        };

        webSocket.send(JSON.stringify(msg));
    }

    private getTotal(){
        let total = 0;

        this.clientResults.forEach((result: Result) => {
            total += result.remainingTime / 1000; // 1 point per second
            total += result.isCorrectAnswer? 10 : 0; // 10 points for correct answer
        });

        this.clientTotal = total;

        return total;
    }

    private renderHighscoreList(highscores: []){

        const highscoreList1 = document.getElementById('highscore-list-1'),
              highscoreList2 = document.getElementById('highscore-list-2'),
              highscoreList3 = document.getElementById('highscore-list-3');

        highscoreList1.innerHTML= '';
        highscoreList2.innerHTML= '';
        highscoreList3.innerHTML= '';

        highscores
            .filter( ({full_name, result}) => (
                   typeof full_name !== 'undefined'
                && typeof result !== 'undefined'
                && result !== null))
            .sort( (a, b) => (b.result - a.result))
            .forEach( (score, idx) => {
                let {full_name, result} = score;

                let displayName:string = full_name;
                let displayResult: number = result.toFixed(2);

                if(typeof full_name !== 'undefined' && full_name.length > 10){
                    displayName = full_name.substr(0,10) + '...';
                }

                let li = document.createElement("LI");
                li.innerHTML = `<span>${idx + 1}.</span>${displayName} | ${displayResult}`;

                if(idx < 10){
                    highscoreList1.appendChild(li);
                } else if(idx < 20) {
                    highscoreList2.appendChild(li);
                } else if(idx < 30) {
                    highscoreList3.appendChild(li);
                }
            });
    }

     private renderReveal(){
        let { text, preText } = this.getWinner();

        let winnerEl = document.getElementById('winner');
        let preWinnerEl = document.getElementById('pre-winner');

        winnerEl.innerText = text;
        preWinnerEl.innerText = preText;

        let clientName = document.getElementById('player' + clientPlayer.getId() + '-name');
        let clientScore = document.getElementById('player' + clientPlayer.getId() + '-score');

        clientName.innerText = clientPlayer.getName();
        clientScore.innerText = String(this.clientTotal.toFixed(2));

        let opponentName = document.getElementById('player' + clientPlayer.getOtherPlayerId() + '-name');
        let opponentScore = document.getElementById('player' + clientPlayer.getOtherPlayerId() + '-score');

        opponentName.innerText = clientPlayer.getOpponentName();
        opponentScore.innerText = String(this.opponentTotal.toFixed(2));

    }

    public saveResult(remainingTime: number, isCorrectAnswer: boolean) {

        let Result = {
            remainingTime,
            isCorrectAnswer
        };

      this.clientResults.push(Result);

    };

    public start(){
        this.postTotal();

        this.views[this.activeView].style.display = 'flex';

        setTimeout( () => this.nextView(), 3000);
        setTimeout(this.fetchResults, 5000); // delayed so both scores are saved and available;
        setTimeout( () => this.nextView(), 9000);
        setTimeout( () => this.nextView(), 15000);
    };

    public hasEnded(){
        return false;
    }

    public next(){

    }
}

export default new ResultsManager();