import gameContent from '../../data/gameContent';

class SoundManager
{
    private sounds: HTMLAudioElement[] = [];
    private preLoaded: boolean = false;

    constructor(){

        Object.values(gameContent).forEach( (lvlContent: {audioPath: string }, idx: number) => {
            let audio = new Audio(lvlContent.audioPath);
            audio.load();

            this.sounds.push(audio);
        });

        document.body.addEventListener('click', () => {
            if(!this.preLoaded){
                this.prePlaySound();
            }
        });
    }

    private prePlaySound(){
        this.sounds.forEach( (soundFrag: HTMLAudioElement) => {
            soundFrag.play();
            soundFrag.pause();
        });

        this.preLoaded = true;
    }

    public playSound(idx: number){
        let sound = this.sounds[idx - 1 ]; //return to 0 based index

        if(sound instanceof Audio){
            sound.play();
        }
    }
}

export default new SoundManager();