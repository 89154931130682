import nl from '../audio/01_nl.mp3';
import fr from '../audio/02_fr.mp3';
import es from '../audio/03_es.mp3';
import lv from '../audio/04_lv.mp3';
import lt from '../audio/05_lt.mp3';
import ro from '../audio/06_ro.mp3';
import pl from '../audio/07_pl.mp3';
import sr from '../audio/08_sr.mp3';
import cz from '../audio/09_cz.mp3';
import hu from '../audio/10_hu.mp3';

const languages = ['Dutch', 'French','Spanish', 'Latvian', 'Lithuanian', 'Romanian', 'Polish', 'Serbian', 'Czech', 'Hungarian'];

export default {
  "lvl1": {
    "audioPath": nl,
    "correctAnswer": "3",
    "countries": {
      "1": languages[8],
      "2": languages[5],
      "3": languages[0], // correct
      "4": languages[9],
      "5": languages[2]
    }
  },
  "lvl2": {
    "audioPath": fr,
    "correctAnswer": "4",
    "countries": {
      "1": languages[2],
      "2": languages[6],
      "3": languages[4],
      "4": languages[1], // correct
      "5": languages[3]
    }
  },
  "lvl3": {
    "audioPath": es,
    "correctAnswer": "2",
    "countries": {
      "1": languages[0],
      "2": languages[2], // correct
      "3": languages[9],
      "4": languages[1],
      "5": languages[5]
    }
  },
  "lvl4": {
    "audioPath": lv,
    "correctAnswer": "1",
    "countries": {
      "1": languages[3], // correct
      "2": languages[6],
      "3": languages[2],
      "4": languages[7],
      "5": languages[4]
    }
  },
  "lvl5": {
    "audioPath": lt,
    "correctAnswer": "4",
    "countries": {
      "1": languages[6],
      "2": languages[5],
      "3": languages[1],
      "4": languages[4], // correct
      "5": languages[9]
    }
  },
  "lvl6": {
    "audioPath": ro,
    "correctAnswer": "3",
    "countries": {
      "1": languages[6],
      "2": languages[7],
      "3": languages[5], // correct
      "4": languages[8],
      "5": languages[1]
    }
  },
  "lvl7": {
    "audioPath": pl,
    "correctAnswer": "4",
    "countries": {
      "1": languages[2],
      "2": languages[8],
      "3": languages[5],
      "4": languages[6], // correct
      "5": languages[3]
    }
  },
  "lvl8": {
    "audioPath": sr,
    "correctAnswer": "2",
    "countries": {
      "1": languages[9],
      "2": languages[7], // correct
      "3": languages[6],
      "4": languages[4],
      "5": languages[5]
    }
  },
  "lvl9": {
    "audioPath": cz,
    "correctAnswer": "5",
    "countries": {
      "1": languages[3],
      "2": languages[2],
      "3": languages[5],
      "4": languages[4],
      "5": languages[8] // correct
    }
  },
  "lvl10": {
    "audioPath": hu,
    "correctAnswer": "1",
    "countries": {
      "1": languages[9], // correct
      "2": languages[3],
      "3": languages[4],
      "4": languages[1],
      "5": languages[8]
    }
  }
};