import gameContent from '../../data/gameContent';
import { Phase } from '../services/PhaseManager';
import ResultsManager from '../services/ResultsManager';
import SoundManager from '../services/SoundManager';
import { updateStatus } from "../app";
import { clientPlayer } from '../services/RegisterPlayer';

class GameSequence implements Phase
{
    readonly totalSteps = Object.keys(gameContent).length;
    private question = 0;
    private lvlData: object;
    private views: HTMLCollectionOf<HTMLElement>;
    private activeView: number = 0;
    private lvlTimeLeft: number = 10000;
    private questionTimer: NodeJS.Timer;
    private selectedOptions: Boolean = false;

    constructor(){
        let container = document.getElementById('game');
        this.views = container.getElementsByClassName('view');

        const playButton = document.getElementById('play');

        playButton.addEventListener('click', e => {
            e.preventDefault();

            updateStatus('waiting');
        });

        const nextButton = document.getElementById('next');

        nextButton.addEventListener('click', e => {
            e.preventDefault();

            updateStatus('waiting');
        });
    }

    private enableNextButton(){
        const nextButton = document.getElementById('next-button');
        nextButton.disabled = false;
    }


    private disableNextButton(){

        const nextButton = document.getElementById('next-button');
        nextButton.disabled = true;
    }

    // start game loop when both players are ready
    private renderNextQuestion(){

        this.selectedOptions = false;
        this.disableNextButton();

        //fetch lvl data
        this.question += 1;
        this.lvlTimeLeft = 10000;
        this.getLvlData();

        // show overlay for 3 seconds with question index
        let questionInto = document.getElementById('question-intro');
        let questionIntoTitle = document.getElementsByClassName('sound-number');

        for (let i = 0; i < questionIntoTitle.length; i++){
            questionIntoTitle[i].innerText = 'Sound ' + this.question;
        }

        questionInto.style.display = 'flex';
        setTimeout(()=> {
            questionInto.style.display = 'none';

            this.renderCountryOptions();
            this.startCountdown();
        }, 3000)
    }

    private renderPlayerNames(){

        const clientBox = document.getElementById('player' + clientPlayer.getId());
        const opponentBox = document.getElementById('player' + clientPlayer.getOtherPlayerId());

        clientBox.innerText = clientPlayer.getName();
        opponentBox.innerText = clientPlayer.getOpponentName();

    }

    private showIntro(){
        this.renderPlayerNames();
        this.nextView(); // Player names

        setTimeout( () => this.startGameCountdown(), 4000);
    }

    private startGameCountdown(){
        let grandTimer = document.getElementById('grandTimer');
        let countDownFrom = 3;
        grandTimer.innerText = String(countDownFrom);

        this.nextView(); // Countdown

        const oneDown = () => {
            if(countDownFrom){
                grandTimer.innerText = String(countDownFrom);
                countDownFrom -= 1;
            } else {
                clearInterval(granTimerInterval);
                this.nextView(); // Game view
                this.renderNextQuestion();
            }
        };

        setTimeout(oneDown,1000);
        let granTimerInterval = setInterval( oneDown, 1000);
    }

    private nextView(){

        this.views[this.activeView].style.display = 'none';
        this.activeView += 1;
        this.views[this.activeView].style.display = 'flex';
    }

    public start() {
        this.views[this.activeView].style.display = 'flex';

        setTimeout( () => this.nextView(), 2000);
        setTimeout( () => this.nextView(), 5000);
    }

    public end(){
        this.views[this.activeView].style.display = 'none';
    }

    public hasEnded(){

        if(this.question === this.totalSteps){
            this.end();
        }

        return this.question === this.totalSteps;
    }

    private playQuestionSound(){
        SoundManager.playSound(this.question);
    }

    public startCountdown() {

        this.playQuestionSound();
        let timerEl = document.getElementById('timer');

        if(typeof timerEl !== 'undefined') {
            this.questionTimer = setInterval(() => {
                if(this.lvlTimeLeft < 100 ) {
                    this.lvlTimeLeft = 0;

                    // @ts-ignore
                    timerEl.innerText = this.lvlTimeLeft / 1000;

                    clearInterval(this.questionTimer);
                } else {
                    this.lvlTimeLeft -= 100;

                    // @ts-ignore
                    timerEl.innerText = this.lvlTimeLeft;
                }
                }, 100)
        }
    }

    // next is invoked when both players have status waiting and game hasn't ended
    public next() {

        if(this.question === 0){
            this.showIntro();
        } else {
            this.renderNextQuestion();
        }
    }

    public checkAnswer(answer: string){
        // @ts-ignore
        let correctAnswer: number = parseInt(this.lvlData.correctAnswer, 10);
        let givenAnswer: number = parseInt(answer, 10) + 1; // restore 0-based index

        console.log(givenAnswer, correctAnswer, correctAnswer == givenAnswer);

        return correctAnswer == givenAnswer;
    }

    public getLvlData(){
        let key = 'lvl' + (this.question);

        // @ts-ignore
        this.lvlData = gameContent[key];
    }

    public getCountries() {
        if(Object.keys(this.lvlData).length){

            // @ts-ignore
            return this.lvlData.countries;
        }

        return {};
    }

    public renderCountryOptions(){
        let countries = this.getCountries(),
            countryOptions = document.getElementById('country-options');

        countryOptions.innerHTML = '';


        // Append country option
        Object.values(countries).forEach( (country, idx: number) => {

            let countryButton = document.createElement('button');
            countryButton.setAttribute('data-id', String(idx));

            // @ts-ignore
            countryButton.innerText = country;

            countryButton.addEventListener('touchstart', (evt) => {
                let target = evt.currentTarget;

                if(target instanceof Element){
                    let selectedCountry: string = target.getAttribute('data-id');
                    this.onCountrySelect(selectedCountry);
                }
            });


            countryButton.addEventListener('click', (evt) => {

                if(!this.selectedOptions) {
                    this.selectedOptions = true;
                    this.enableNextButton();

                    let target = evt.currentTarget;

                    evt.currentTarget.style.backgroundColor = '#3B9E98';
                     evt.currentTarget.style.color = '#fff';

                    if(target instanceof Element){
                        let selectedCountry: string = target.getAttribute('data-id');
                        this.onCountrySelect(selectedCountry);
                    }
                }
            });

            countryOptions.appendChild(countryButton);
        })

    }

    private onCountrySelect(selectedCountry: string) {

        clearInterval(this.questionTimer);

        if(this.question > ResultsManager.getResults().length) {

            let isAnswerCorrect = this.checkAnswer(selectedCountry);

            ResultsManager.saveResult(this.lvlTimeLeft, isAnswerCorrect);
        }
    }
}

export default new GameSequence();