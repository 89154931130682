import '../scss/index.scss';
import PhaseManager from './services/PhaseManager';
import ResultsManager from './services/ResultsManager';
import RegisterPlayer from './services/RegisterPlayer';
import { clientPlayer } from './services/RegisterPlayer';

let status: string = 'connecting...';

setTimeout(()=>{
	document.getElementById('loading').style.display = 'none';
},500)

export interface Msg {
    type: string; // request or command
	subType: string;
    body: string;
}

export function updateStatus(nextStatus: string){
	status = nextStatus;
	let myStatusEl = document.getElementById('status-player-' + clientPlayer.getId());

	if(typeof status !== 'undefined') {
		myStatusEl.innerHTML = status;

		let msg: Msg = {
			type: 'command',
			subType: 'status',
			body: status
		};

		webSocket.send(JSON.stringify(msg));
	}
}

function onReceivedStatusOtherPlayer(status: string){

	let otherStatusEl = document.getElementById('status-player-' + clientPlayer.getOtherPlayerId());
	otherStatusEl.innerHTML = status;
}

function processRequest(subType: string, body: string): void {
	switch (subType) {
		case 'status':
			updateStatus(body);
			break;
	}
}

function processCommand(subType: string, body: string): void {
	switch (subType) {
		case 'game':
			PhaseManager.processStep(body);
			break;
		case 'assignId':
			clientPlayer.setId(body);
			updateStatus('connected');
			break;
		case 'statusOtherPlayer':
			onReceivedStatusOtherPlayer(body);
			break;
		case 'result':
			ResultsManager.onReceiveResult(Number(body));
			break;
		case 'results':
			ResultsManager.onReceiveAllResults(body);
			break;
		case 'registered':
			RegisterPlayer.onPlayerRegistered(body);
			break;
		case 'opponentRegistered':
			clientPlayer.setOtherPlayerName(body);
			break;
	}
}


let webSocketLocation = 'ws://sah-game.demo.o.schuttelaar.net:8010';
let terminate = false;

export let webSocket;
function startWS(){
	webSocket = new WebSocket(webSocketLocation);

	// On Open
	webSocket.onopen = () => {
		console.log('connected');
	};

// Route messages
	webSocket.onmessage = (event) => {

		if(typeof event.data !== 'undefined' && event.data !== 'undefined') {
			let parsedData: Msg = JSON.parse(event.data);

			switch(parsedData.type){
				case 'request':
					processRequest(parsedData.subType, parsedData.body); // expect type & detail.
					break;
				case 'command':
					processCommand(parsedData.subType, parsedData.body)
					break;
			}
		} else {
			console.log('Message has wrong format: ', event);
		}
	};

	webSocket.onclose = function(){
		if(!terminate){
			console.log('trying to reconnect');
			setTimeout(function(){ startWS() }, 500);
		}
	};
}

let reloadButton1 = document.getElementById('play-again');
let reloadButton2 = document.getElementById('reload');

reloadButton1.addEventListener('touchstart', () => {
	terminate = true;
	webSocket.close();
	window.location.reload();
});

reloadButton2.addEventListener('touchstart', () => {
	terminate = true;
	webSocket.close();
	window.location.reload();
});

startWS();

document.addEventListener('visibilitychange', (event) => {
	if (document.visibilityState == 'hidden') {
		webSocket.close();
	}
});


