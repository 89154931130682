class Player
{
    private _id: number;
    private name: string = '';
    private otherPlayerName: string = '';

    public getId(): number {
        return this._id;
    }

    public setId(id: string) {
        this._id = parseInt(id, 10);
        console.log('player is', id);
    }

    public getOtherPlayerId() {
        return this._id - 1? 1 : 2;
    }

    public getOpponentName() {
        if(this.otherPlayerName.length > 10){
            return this.otherPlayerName.substr(0,10) + '...';
        }

        return this.otherPlayerName;
    }

    public setOtherPlayerName(name: string){
        console.log('received opponent: ', name);
        this.otherPlayerName = name;
    }

    public setName(name: string){
        this.name = name;
    }

    public getName(){
        if(this.name.length > 10){
            return this.name.substr(0,10) + '...';
        }

        return this.name;
    }

}

export default Player;
